import { NavbarEng, SidebarEng } from "../components/Navigation"
import { Link } from "react-router-dom"
import Regresar from "../components/Regresar"
import Header from "../components/Header"
import educacion from "./../styles/educacion.module.css"
import cx from "classnames"
import FooterEng from "../components/FooterEng"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export default function EducacionEng() {

    const tortuga = useRef(null);
    const timeline_tortuga = useRef();
    useEffect(() => {
        timeline_tortuga.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.talleres_tortuga',
                start: '60% bottom',
                end: '+=120 0',
                scrub: true,
            },
        });
        timeline_tortuga.current.to(tortuga.current, {x: 70, y:-120, duration: 3,});
    });

    return (
        <>
            <NavbarEng/>
            <SidebarEng espLink={ "/educacion "}/>
            <Header routeName="educacion" altName="Tortuga" headerTitle="EDUCATION" titleClasses="default"/>

            {/* Talleres */}
            <div className={ cx(educacion.talleres, 'section')}>
                <div className={ cx(educacion.talleres_content, 'content') }>
                    <div className={ educacion.talleres_title }>
                        <div className={ 'title' }>WE FOSTER THE RAISING OF AWARENESS OF OUR OCEANS BY PEAKING OUR SOCIETY´S INTEREST, THROUGH AN UNPARALLELED JOURNEY OF THE SEA OF CORTEZ´S ECOSYSTEMS.</div>
                    </div>
                    <div className={ educacion.talleres_des }>
                        <div className={ educacion.talleres_des__t1 }>Students will have the opportunity to admire the habitats of over 350 species, and to learn through a magnificent journey, from the coast up to the deep sea, with glimpses of sharks, seahorses, sea turtles, jellyfish, corals, and much more.</div>
                        <div className={ educacion.talleres_des__t1 }>Experience this marvelous sea world through 19 halls designed in open and closed spaces, and world-class facilities.</div>
                        <div className={ cx(educacion.talleres_des__title, 'title') }>WORKSHOPS FOR RESEARCHERS</div>
                        <div className={ educacion.talleres_des__t1 }>Get to know our annual Workshop Program, given by teachers with doctorates in Biology and Environmental Sciences.</div>
                        <img className={ educacion.talleres_des__img } src="/images/educacion/tiburon.png" alt="Tiburón "/>
                        <a href="https://ciomc.org/#programas" target="_blank" className={ educacion.talleres_des__btn } to="/">See Program</a>
                    </div>
                </div>
                <Regresar link="/eng/" des="Home" />
                <img ref={ tortuga } className={ educacion.talleres_tortuga } src="/images/educacion/tortuga.png" alt="Tortuga" />
            </div>

            <FooterEng/>
        </>
    )
}