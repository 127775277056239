import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import Footer from "../components/Footer";

import { Accordion } from "react-accessible-accordion"
import { Pregunta, PreguntaAccordion } from "../components/FaqElements";

import faq from './../styles/faq.module.css'
import cx from 'classnames'

export default function Faq() {
    return(
        <>
            <Navbar/>
            <Sidebar engLink={ "/eng/faq" }/>
            <Header routeName="habitats" altName="Niño mirando pecera" headerTitle="PREGUNTAS/FRECUENTES" titleClasses="default"/>

            {/* Preguntas frecuentes
            <div className={ cx(faq.preguntas, 'section') }>
                <div className={ cx(faq.preguntas_content, 'content') }>
                    <Pregunta pregunta="¿Puedo tomar fotografías?" respuesta="Puedes tomar todas las fotografías que quieras sin hacer uso de flash y sin ningún costo extra. No se permite el ingreso con equipo extra como trípie, lentes, etc."/>
                    <Pregunta pregunta="¿Cuentan con estacionamiento?" respuesta="Frente al Gran Acuario contamos con estacionamiento. Tiene un costo de $50.00 de lunes a jueves y de $ 60.00 de viernes a domingo."/>
                    <Pregunta pregunta="¿Puedo ingresar con alimentos?" respuesta="Por seguridad e higiene, no se permite la entrada con alimentos ni bebidas."/>
                    <Pregunta pregunta="¿Puedo llevar carreola para un bebé?" respuesta="Sí están permitidas las corriolas sencillas, no las carriolas dobles."/>
                    <Pregunta pregunta="¿Tienen lockers?" respuesta="Por lo pronto no tenemos lockers, por ello, no está permitido ingresar con bolsas y/o mochilas."/>
                    <Pregunta pregunta="¿Cuáles son sus horarios?" respuesta="Horarios: de lunes a domingo de 10 a 18 horas."/>
                </div>
            </div>
            */}

            <div className={ cx(faq.preguntas, 'section') }>
                <div className={ cx(faq.preguntas_content, 'content') }>
                    <div className={ faq.preguntas_title }>¿Tienes dudas sobre el Gran Acuario Mazatlán? Aquí te presentamos las preguntas frecuentes, si no encuentras la respuesta a tu duda contáctanos vía whatsapp al <a href="tel:6699817815">(669) 139 0134</a></div>
                    <Accordion className={ faq.faq_accordion }>
                        <PreguntaAccordion pregunta="¿Puedo tomar fotografías?" respuesta="Puedes tomar todas las fotografías que quieras sin hacer uso de flash y sin ningún costo extra. No se permite el ingreso con equipo extra como trípie, lentes, etc."/>
                        <PreguntaAccordion pregunta="¿Cuentan con estacionamiento?" respuesta="Frente al Gran Acuario contamos con estacionamiento. Tiene un costo de $50.00 de lunes a jueves y de $ 60.00 de viernes a domingo."/>
                        <PreguntaAccordion pregunta="¿Puedo ingresar con alimentos?" respuesta="Por seguridad e higiene, no se permite la entrada con alimentos ni bebidas."/>
                        <PreguntaAccordion pregunta="¿Puedo llevar carriola para un bebé?" respuesta="Sí están permitidas las carriolas sencillas, no las carriolas dobles."/>
                        <PreguntaAccordion pregunta="¿Tienen lockers?" respuesta="Por lo pronto no tenemos lockers, por ello, no está permitido ingresar con bolsas y/o mochilas."/>
                        <PreguntaAccordion pregunta="¿Cuáles son sus horarios?" respuesta="Horarios: de lunes a domingo de 9 de la mañana a 7 de la tarde. El cierre de taquillas es a las 5 de la tarde."/>
                        <PreguntaAccordion pregunta="¿Que pasa con los objetos perdidos?" respuesta="Los objetos perdidos que se logren identificar dentro del recinto, se resguardaran en las oficinas de información, favor de comunicarse al teléfono (669) 689 0501  donde la operadora le guiará con el proceso."/>
                    </Accordion>
                </div>
            </div>

            <Footer/>
        </>
    )
}