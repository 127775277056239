import m from "../styles/modal.module.css"
import cx from "classnames"
import { ResponsiveImage600v2 } from "./ResponsiveImage"

export function Modal({ open, onClose }) {
    
    if(!open) return null

    return(
        <div id="overlay" className={ m.overlay } onClick={ onClose }>
            <div onClick={ (e) => {e.stopPropagation()} } className={ m.modalContainer }>
                <ResponsiveImage600v2 route="/home/aviso_importante" alt="Aviso importante"/>
            </div>
        </div>
    )
}