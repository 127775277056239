import { Link } from "react-router-dom"
import { Navbar, Sidebar } from "../components/Navigation"
import Header from "../components/Header"
import Regresar from "../components/Regresar"
import { Actividad } from "../components/Actividad"
import Footer from "../components/Footer"
import grupos from "../styles/grupos.module.css"
import cx from "classnames"

export default function Grupos() {
    return (
        <>
            <Navbar/>
            <Sidebar engLink={ "/eng/groups" }/>
            <Header routeName="grupos" altName="Personas mirando a pecera" headerTitle="GRUPOS" titleClasses="default"/>

            <div className={ grupos.grupos }>
                <div className={ grupos.grupos_content }>
                    <div className={ grupos.grupos_des }>
                        <div className={ grupos.grupos_des__t1 }>Tours y actividades privadas</div>
                        <div className={ grupos.grupos_des__t2 }>En el Gran Acuario Mazatlán hay una gran variedad de actividades, se dividen en grupos empresariales y grupos escolares.</div>
                    </div>
                    <div className={ grupos.grupos_opts }>
                        <div className={ cx(grupos.grupos_opts_el, grupos.escolar ) }>
                            <a href="https://tickets.granacuario.com/983-grupos-y-escuelas"></a>
                            <div className={ grupos.grupos_opts_el__title }>Visitas escolares</div>
                            <div className={ grupos.grupos_opts_el__des }>El mundo marino y sus maravillosos seres son parte fundamental de nuestro planeta. En Gran Acuario Mazatlán fomentamos el aprendizaje y abrimos nuestras puertas a grupos escolares, así promovemos la conservación y cuidado de nuestros océanos.</div>
                            {/*
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Visitas escolares<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Durmiendo con tiburones<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Fiestas de cumpleaños<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            */}
                        </div>
                        <div className={ cx(grupos.grupos_opts_el, grupos.empresarial ) }>
                            <a href="https://tickets.granacuario.com/983-grupos-y-escuelas"></a>
                            <div className={ grupos.grupos_opts_el__title }>Visitas de grupos</div>
                            <div className={ grupos.grupos_opts_el__des }>Acompañado de tu grupo, podrás realizar un recorrido, en el que conocerás las especies de la zona de jardín, y recorrer los 26 asombrosos y coloridos hábitats que se encuentran en el edificio principal.</div>
                            {/*
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Espacios para eventos<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Auditorio para 200 personas<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            <Link to="/" className={ grupos.grupos_opts_el__link }>Visitar corporativas<img src="/images/grupos/chevron.png" alt="Flecha derecha"/></Link>
                            */}
                        </div>
                    </div>
                    <Regresar link="/" des="Inicio"/>
                </div>
            </div>

            <Footer/>
        </>
    )
}