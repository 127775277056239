import { Link } from "react-router-dom"

export default function Footer() {
    return (
        <>
            { /* Footer */ }
            <div className="footer section">
                <div className="content">
                    <div className="footer_con">
                        <div className="footer_con__title title">SOBRE NOSOTROS</div>
                        <a href="https://ciomc.org/#programas" target="_blank" className="footer_con__el">Programas de conservación</a>
                        <a href="mailto:medios@granacuario.com" className="footer_con__el">Medios de comunicación</a>
                        <a href="mailto:rh@granacuario.com" className="footer_con__el">Bolsa de trabajo</a>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">ENCUÉNTRANOS</div>
                        <a href="https://goo.gl/maps/nrpPfrAL1cZewPbv8" target="_blank" className="footer_con__el">Mapa</a>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">CONÉCTATE CON NOSOTROS</div>
                        <div className="footer_con_social">
                            <a href="https://www.facebook.com/granacuariomazatlan" target="_blank" className="footer_con__el"><img src="/images/footer/fb.svg" alt="Facebook Icon"/></a>
                            <a href="https://www.instagram.com/granacuariomazatlan/" target="_blank" className="footer_con__el"><img src="/images/footer/ins.svg" alt="Instagram Icon"/></a>
                            <a href="https://twitter.com/GranAcuarioMzt" target="_blank" className="footer_con__el"><img src="/images/footer/tw.svg" alt="Twitter Icon"/></a>
                        </div>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">SERVICIO DE ASISTENCIA</div>
                        <Link to="/contacto" className="footer_con__el">Contáctanos</Link>
                        <Link to="/faq" className="footer_con__el">Preguntas frecuentes</Link>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">DIRECCIÓN Y TELÉFONO</div>
                        <a href="https://goo.gl/maps/nrpPfrAL1cZewPbv8" target="_blank" className="footer_con__el">Av. de los Deportes 111, Tellería, 82017 Mazatlán, Sinaloa.</a>
                        <a href="tel:6696890501" className="footer_con__el">Tel. (669) 689 0501</a>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">INSTITUCIONES</div>
                        <div className="footer_con_fund">
                            <a href="https://ciomc.org/" target="_blank" className="footer_con__el"><img src="/images/footer/ciomc.png" alt="CIOMC logo"/></a>
                            <a href="https://www.ciad.mx/" target="_blank" className="footer_con__el"><img src="/images/footer/ciad.png" alt="CIAD logo"/></a>
                            <a href="https://www.icmyl.unam.mx/" target="_blank" className="footer_con__el"><img src="/images/footer/icml.png" alt="ICML logo"/></a>
                        </div>
                    </div>
                </div>
            </div>
            { /* Copyright */ }
            <div className="copy section"><div className="content">© 2023 Todos los derechos reservados. <a href="/images/tyc.pdf" target="_blank">Términos y condiciones.</a> <a href="/images/reglamento.pdf" target="_blank">Reglamento Gran Acuario Mazatlán.</a></div></div>
        </>
    )
}