'use client'
import { NavbarEng, SidebarEng } from "../components/Navigation"
import Header from "../components/Header"
import { Pregunta, EspecieEng } from "../components/HabitatElements"
import FooterEng from "../components/FooterEng"
import { useParams } from "react-router-dom"
import ex from "../styles/exhibicionTipo.module.css"
import cx from "classnames"
import { Link } from "react-router-dom"
import { habitatsEng } from "../array/habitats"

export default function Habitat() {

    const { habitat }  = useParams();
    const actual = habitatsEng.find(element => element.id == habitat);

    return (
        <>
            <NavbarEng/>
            <SidebarEng espLink={ "/habitats/" + actual.carpeta + "/" + actual.header_class }/>
            <Header routeName={ actual.carpeta + "/" + actual.header_class } titleClasses={ actual.carpeta } altName="Hábitat header image" headerTitle={ actual.nombre }/>

            {/* Descripción */}
            <div className="section">
                <div className="content">
                    <div className={ cx(ex.habitat__t1, ex.des) }>{ actual.description }</div>

                    {/* Especies */}
                    <div className={ ex.habitat_con }>
                        {   actual.especies != undefined &&
                                actual.especies.map(el => <EspecieEng route={ el.route } carpet_route={ el.carpet_route } exhibicion={ actual.carpeta } habitat={ actual.header_class } nombre={ el.nombre_comun.replace('/', ' ') } nombreDisplay={ el.nombre_display } key={ el.route }/>) }
                    </div>

                    {/* Preguntas */}
                    <div className={ ex.preguntas }>
                        {   actual.preguntas != undefined &&
                                actual.preguntas.map((el, index) => <Pregunta pregunta={el.pregunta} respuesta={el.respuesta} key={index}/>) }
                    </div>
                </div>
                <div className="contenido_back"><Link to={ "/eng/habitats/" + actual.habitat }></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>{ actual.habitat.charAt(0).toUpperCase() + actual.habitat.slice(1) }</div>
            </div>

            <FooterEng/>
        </>
    )
}