import { Navbar, Sidebar } from "../components/Navigation";
import Header from "../components/Header";
import { ResponsiveImage, ResponsiveImage600 } from "../components/ResponsiveImage";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import { Modal } from "../components/Modal";
import { useState } from "react";
import ScrollEvents from "../components/ScrollEvents";

import "./../styles/home.css";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
    /* Funcionamiento del Modal */
    const [blockScroll, allowScroll] = ScrollEvents()
    const [openModal, setOpenModal] = useState(false);
    let gamModal = localStorage.getItem('gamModalTimes');
    if (gamModal != null) {
        if(gamModal > 5){
            localStorage.removeItem('gamModalTimes');
        } else {
            gamModal++;
            localStorage.setItem('gamModalTimes', gamModal);
        }
    } else {
        blockScroll();
        setOpenModal(true);
        localStorage.setItem('gamModalTimes', 1);
    }


    { /* Entradas de texto */ }
    const historia__title = useRef(null);
    const historia__des_anim1 = useRef(null);
    const historia__des_anim2 = useRef(null);
    const tarifas_anim1 = useRef(null);
    const tarifas_anim2 = useRef(null);
    const tarifas_anim3 = useRef(null);
    const tarifas_anim4 = useRef(null);
    useEffect(() => {
        gsap.from(historia__title.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.historia__title' });
    }, []);
    useEffect(() => {
        gsap.from(historia__des_anim1.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.historia__des_anim1' });
    }, []);
    useEffect(() => {
        gsap.from(historia__des_anim2.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.historia__des_anim2' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim1.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim1' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim2.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim2' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim3.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim3' });
    }, []);
    useEffect(() => {
        gsap.from(tarifas_anim4.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.tarifas_anim4' });
    }, []);

    { /* Entrada de bloques por izquierda */ }
    const fromLeft = useRef(null);
    const fromLeft2 = useRef(null);
    useEffect(() => {
        gsap.to(fromLeft.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromLeft' });
    }, []);
    useEffect(() => {
        gsap.to(fromLeft2.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromLeft2' });
    }, []);

    { /* Entrada de bloques por derecha */ }
    const fromRight = useRef(null);
    const fromRight2 = useRef(null);
    useEffect(() => {
        gsap.to(fromRight.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromRight', });
    }, []);
    useEffect(() => {
        gsap.to(fromRight2.current, { duration: 1.5, transform: 'translateX(0)', delay: .5, ease: 'circ.out', scrollTrigger: '.fromRight2', });
    }, []);

    { /* Movimiento medusa */ }
    const exhibiciones_medusa = useRef(null);
    const timeline_medusa = useRef();
    useEffect(() => {
        timeline_medusa.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.exhibiciones_medusa',
                //markers: true,
                start: '25% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_medusa.current.to(exhibiciones_medusa.current, {y: -120, duration: 2,});
    });

    { /* Movimiento tiburón */ }
    const tiburon = useRef(null);
    const timeline_tiburon = useRef();
    useEffect(() => {
        timeline_tiburon.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.tiburon',
                //markers: true,
                start: '25% bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_tiburon.current.to(tiburon.current, {x: 100, y: 120, duration: 3,});
    });

    { /* Movimiento constante */ }
    const exhibiciones_des_con_el__icon = useRef(null);
    useEffect(() => {
        gsap.from(exhibiciones_des_con_el__icon.current, { duration: 2, opacity: 0, x: -40, ease: 'power2.out', repeat: -1, repeatDelay: .2 });
    }, []);

    return (
        <>
            <Modal open={ openModal } onClose={ () => {setOpenModal(false);allowScroll();} } />

            <Navbar/>
            <Sidebar engLink={ "/eng/" }/>
            <Header routeName="home" altName="Gran Acuario Mazatlán Render" classes="home_header"/>

            { /* Historia */ }
            <div className="historia section">
                <div className="content">
                    <div className="historia__title title" ref={ historia__title }>LLENOS DE VIDA</div>
                    <div className="historia__des historia__des_anim1" ref={ historia__des_anim1 }>Gran Acuario Mazatlán, es el más grande de México y más importante en Latinoamérica, dedicado al Mar de Cortés. Con un enfoque en la educación, la investigación y divulgación del conocimiento, marcará un referente en los acuarios del mundo.</div>
                    <div className="historia__des historia__des_anim2" ref={ historia__des_anim2 }>Ubicado estratégicamente en el Parque Central, Gran Acuario Mazatlán abre todos los días de 9:00 de la mañana a 7:00 de la tarde.</div>
                </div>
                <img src="/images/home/peces.png" srcSet="/images/home/peces.webp" alt="Peces" className="historia_peces"/>
                <img src="/images/home/historia_bg.png" srcSet="/images/home/historia_bg.webp" alt="Momentos memorables del mundo marino" className="historia_bg"/>
            </div>

            { /* Entradas */ }
            <div className="entradas">
                <div className="entradas_img"><img className="fromLeft" ref={ fromLeft } src="/images/home/entradas.png" srcSet="/images/home/entradas.webp" alt="Niño viendo pecera"/></div>
                <div className="entradas_des fromRight" ref={ fromRight }>
                    <a href="https://tickets.granacuario.com/"></a>
                    <div className="entradas_des__title title">TICKETS</div>
                    <div className="entradas_des__des">Tendrás acceso a conocer los maravillosos 26 hábitats que se encuentran en nuestro edificio, además de lo que ofrece la zona alrededor de él. Así mismo tendrás la oportunidad de asistir a la presentación de aves en el auditorio al aire libre.</div>
                </div>
            </div>

            { /* TARIFAS Y HORARIOS */ }
            <div className="tarifas section">
                <div className="content">
                    <div className="tarifas_title"><div className="title tarifas_anim1" ref={ tarifas_anim1 }>SOMOS FORMATIVOS, CURIOSOS, APASIONADOS, GUARDIANES, ABIERTOS; <span className="title">SOMOS LLENOS DE VIDA</span></div></div>
                    <div className="tarifas_des">
                        <div className="tarifas_des__t1 tarifas_anim2" ref={ tarifas_anim2 }><strong>Nuestra misión:</strong> Conectamos a la sociedad con el Acuario del Mundo, en un espacio de reflexión que despierta la imaginación, provoca emociones, inspira y genera un cambio de actitudes y voluntades para sanar el planeta.</div>
                        <div className="tarifas_des__t1 tarifas_anim3" ref={ tarifas_anim3 }><strong>Nuestra visión:</strong> Emerger como una sociedad que ame y se reconozca como parte del mundo natural.</div>
                        {/*<div className="tarifas_des__btn tarifas_anim4" ref={ tarifas_anim4 }><img src="/images/header/entradas.svg" alt="Tickets Icon"/>TARIFAS Y HORARIOS</div>*/}
                    </div>
                </div>
            </div>

            { /* EXHIBICIONES */ }
            <div className="exhibiciones">
                <div className="exhibiciones_title title fromLeft2" ref={ fromLeft2 }><Link to="/habitats"></Link>HÁBITATS</div>
                <ResponsiveImage route="home/exhibiciones" alt="Medusas en pecera" classes="exhibiciones_bg"/>
                <picture>
                    <source type="image/webp" srcSet="/images/home/exhibiciones_medusa.webp" media="(min-width:600px)"/>
                    <source type="image/png" srcSet="/images/home/exhibiciones_medusa.png" media="(min-width:600px)"/>
                    <img src="/images/home/exhibiciones_medusa@500.png" ref={ exhibiciones_medusa } srcSet="/images/home/exhibiciones_medusa@500.webp" alt="Medusas" className="exhibiciones_medusa"/>
                </picture>
            </div>

            { /* ACTIVIDADES */ }
            <div className="actividades section">
                <div className="content">
                    <div className="actividades_img">
                        <img ref={ tiburon } className="actividades_img__tiburon tiburon" src="/images/home/tiburon.png" alt="Tiburon"/>
                    </div>
                    <div className="actividades_des">
                        <div className="actividades_des__t1">Grandiosas actividades para disfrutar en el Gran Acuario, para toda la familia.</div>
                    </div>
                </div>
                <img className="actividades_bg" src="/images/home/llenos_de_vida.png" alt="somos llenos de vida"/>
                <div ref={ fromRight2 } className="actividades__title title fromRight2"><img src="/images/default/tramado.svg" alt="Tramado"/><Link to="/actividades"></Link>ACTIVIDADES</div>
            </div>

            {/*
            <div className="exhibiciones_des section">
                <div className="content">
                    <div className="exhibiciones_des_links">
                        <Link to="/habitats" className="exhibiciones_des_links__el title">EXPERIENCIAS</Link>
                        <Link to="#" className="exhibiciones_des_links__el title">HORARIOS</Link>
                    </div>
                    <div className="exhibiciones_des_sep"></div>
                    <div className="exhibiciones_des_con">
                        <div className="exhibiciones_des_con_el">
                            <div className="exhibiciones_des_con_el__t1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint quasi expedita, ab saepe velit modi enim repellat nobis natus perferendis.</div>
                            <div className="exhibiciones_des_con_el__t1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus magnam ad ullam illum dolorem? Quos quisquam quae reprehenderit aliquam dicta quaerat enim vero dolores, tempora quis delectus porro nihil rerum.</div>
                            <img className="exhibiciones_des_con_el__icon" ref={ exhibiciones_des_con_el__icon } src="/images/home/exhibiciones_arrow.svg" alt="Arrow"/>
                        </div>
                        <div className="exhibiciones_des_con_el">
                            <div className="exhibiciones_des_con_el__t2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus sit officia neque, totam beatae placeat illo dolores recusandae saepe impedit rerum doloremque.</div>
                            <div className="exhibiciones_des_con_el__t2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint corrupti deleniti adipisci animi, quae amet facere nobis. Sapiente recusandae est illo porro fuga asperiores quisquam quidem quibusdam itaque architecto eius facilis, eum odio accusantium tempore quasi reiciendis id nostrum doloremque!</div>
                            <div className="exhibiciones_des_con_el__t2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facilis facere dolores deleniti at accusamus ipsum?</div>
                        </div>
                    </div>
                    <div className="exhibiciones_des__final title">VISITA EL ACUARIO MÁS GRANDE Y QUERIDO DE MÉXICO</div>
                </div>
                <img src="/images/home/exhibiciones_tramado.svg" alt="Tramado" className="exhibiciones_des__bg"/>
            </div>
            */}
            <Footer/>
        </>
    )
}