import { NavbarEng, SidebarEng } from "../components/Navigation"
import Regresar from "../components/Regresar"
import Header from "../components/Header"
import visita from "./../styles/visita.module.css"
import cx from "classnames"
import FooterEng from "../components/FooterEng"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export default function VisitaEng() {
    {/* Animaciones */}
    const caballito = useRef(null);
    const caballito_fondo = useRef(null);
    const timeline_caballito = useRef();
    const timeline_caballito_fondo = useRef();

    useEffect(() => {
        timeline_caballito.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.recomendaciones_des_caballito',
                start: '40% bottom',
                end: '+=300 0',
                scrub: true,
            },
        });
        timeline_caballito.current.from(caballito.current, {y: 200, duration: 3});
    });

    useEffect(() => {
        timeline_caballito_fondo.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.recomendaciones_des_caballito_fondo',
                start: '40% bottom',
                end: '+=300 0',
                scrub: true,
            },
        });
        timeline_caballito_fondo.current.to(caballito_fondo.current, {y: 100, duration: 3});
    });

    return (
        <>
            <NavbarEng/>
            <SidebarEng espLink={ "/visita" }/>
            <Header routeName="visita" altName="Persona frente a pecera" headerTitle="PLAN/YOUR VISIT" titleClasses="default"/>

            {/* Tarifa y horarios */}
            <div id="tarifas" className={ cx(visita.tarifas, 'section') }>
                <div className={ cx(visita.tarifas_content, 'content') }>
                    <div className={ visita.tarifas_title }>
                        <div className="title">USEFUL INFORMATION AND RECOMMENDATIONS TO PLAN YOUR VISIT TO THE AQUARIUM</div>
                    </div>
                    <div className={ visita.tarifas_des }>
                        <div className={ visita.tarifas_des__t1 }>Rates and Operating Hours</div>
                        <div className={ visita.tarifas_des__t2 }>The Gran Acuario Mazatlán is the largest in Mexico, and a landmark aquarium in Latin America dedicated to the Sea of Cortez. Focused on education, research, and knowledge dissemination, it´ll be a benchmark for aquariums across the world.</div>
                    </div>
                </div>
            </div>

            {/* Recomendaciones */}
            <div id="recomendaciones" className={ visita.recomendaciones }>
                <div className={ visita.recomendaciones_des }>
                    <img className={ visita.recomendaciones_des_tramado } src="/images/visita/tramado.svg" alt="Tramado"/>
                    <div className={ visita.recomendaciones_des__title }>Recommendations for visiting the Gran Acuario Mazatlán</div>
                    <div className={ visita.recomendaciones_des_animaciones }>
                        <img ref={ caballito }className={ visita.recomendaciones_des_caballito } src="/images/visita/caballito.png" alt="Caballito de mar"/>
                        <img ref={ caballito_fondo }className={ visita.recomendaciones_des_caballito_fondo } src="/images/visita/caballito_fondo.png" alt="Caballito de mar"/>
                    </div>
                </div>
                <div className={ visita.recomendaciones_con }>
                    <div className={ visita.recomendaciones_con__el }>We recommend that you plan your visit in advance.</div>
                    <div className={ visita.recomendaciones_con__el }>We recommend that you arrive at exhibits 30 minutes early.</div>
                    <div className={ visita.recomendaciones_con__el }>We recommend that you use comfortable shoes and adequate clothing to visit the interior and exterior of our facilities.</div>
                    <div className={ visita.recomendaciones_con__el }>Entrance is free for children under the age of 4.</div>
                    <div className={ visita.recomendaciones_con__el }>Ticket booths close one hour before the Aquarium´s closing time.</div>
                    <div className={ visita.recomendaciones_con__el }>Ask for re-entry requirements at the Information Booth, before leaving the premises with the intention of re-entering.</div>
                </div>
            </div>

            {/* Ruta
            <div id="ruta" className={ visita.ruta }>
                <div className={ cx(visita.ruta_content, 'content') }>
                    <div className={ visita.ruta_des }>
                        <div className={ visita.ruta_des__t1 }>Encuentra la mejor ruta para llegar al Gran Acuario Mazatlán</div>
                        <div className={ visita.ruta_des__t2 }>Es sencillo gracias a las indicaciones a lo largo de toda la ciudad. Además contamos con un parking donde estacionar el vehículo, parking de bicicletas, y una red de autobuses que nos conectan con distintos puntos de Sinaloa.</div>
                    </div>
                    <div className={ visita.ruta_con }>
                        <div className={ visita.ruta_con_el }>
                            <img className={ visita.ruta_con_el__icon } src="/images/visita/auto.png" alt="Auto" />
                            <div className={ visita.ruta_con_el__des }>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                        </div>
                        <div className={ visita.ruta_con_el }>
                            <img className={ visita.ruta_con_el__icon } src="/images/visita/bici.png" alt="Bicicleta" />
                            <div className={ visita.ruta_con_el__des }>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                        </div>
                        <div className={ visita.ruta_con_el }>
                            <img className={ visita.ruta_con_el__icon } src="/images/visita/avion.png" alt="Avión" />
                            <div className={ visita.ruta_con_el__des }>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                        </div>
                        <a href="https://goo.gl/maps/d4xZys6vJuKmsZ7M8" target="_blank" className={ visita.ruta_con_link }>Ver en el mapa</a>
                    </div>
                </div>
            </div>
            */}

            {/* Plano de acuario */}
            <div id="plano" className={ visita.plano }>
                <img className={ visita.text } src="/images/visita/visita-right-eng.png" srcSet="/images/visita/visita-right-eng.webp" alt="Visita el acuario"/>
                <div className={ cx(visita.plano_content, 'content') }>
                    <img className={ visita.plano_mapa } src="/images/visita/mapa2.png" srcSet="/images/visita/mapa2.webp" alt="Mapa Acuario" />
                </div>

                <Regresar link="/eng/" des="Inicio" />
            </div>

            <FooterEng/>
        </>
    )
}