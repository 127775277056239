import { NavbarEng, SidebarEng } from "../components/Navigation"
import FooterEng from "../components/FooterEng"
import { CioDescriptionEng, Element } from "../components/HabitatElements"
import Header from "../components/Header"
import { Link } from "react-router-dom"
import "./../styles/exhibicionElement.css"
import { CostaHabitatsEng } from "../array/habitats"

export default function CostaEng() {
    return (
        <>
            <NavbarEng/>
            <SidebarEng espLink={ "/habitats/costa" }/>
            <Header routeName="costa" altName="Niño mirando pecera" headerTitle="COAST"/>

            <div className="contenido">
                <CioDescriptionEng 
                    des={ [
                        'The coast: where sea and land meet, an ever-changing and dynamic space. Diverse ecosystems come together, including the intertidal zone, mangroves, marshes and wetlands, seagrass meadows and coral reefs, among others.',
                        'The coast is among the areas with the most abundant forms of life in the ocean.',
                        'Due to its close and accessible nature, it´s the area we know best.'
                    ] }
                />
                <div className="contenido_ops">
                    {CostaHabitatsEng.map((habitat) => (  
                        <Element link={ habitat.link } nombre={ habitat.nombre } key={ habitat.nombre }/>
                    ))}
                </div>
                <div className="contenido_back"><Link to="/eng/habitats"></Link><img src="/images/tierra/arrow.svg" alt="Flecha atrás"/>Habitats</div>
            </div>

            <FooterEng/>
        </>
    )
}