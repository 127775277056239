import { NavbarEng, SidebarEng } from "../components/Navigation"
import { Link } from "react-router-dom"
import Regresar from "../components/Regresar"
import Header from "../components/Header"
import investigacion from "./../styles/investigacion.module.css"
import cx from "classnames"
import FooterEng from "../components/FooterEng"

export default function InvestigacionEng() {
    return (
        <>
            <NavbarEng/>
            <SidebarEng espLink={ "/investigacion" }/>
            <Header routeName="investigacion" altName="Tortugas" headerTitle="RESEARCH/AND CONSERVATION" titleClasses="default"/>

            {/* Centro de Investigación */}
            <div className={ cx(investigacion.ciomc, 'section') }>
                <div className={ investigacion.ciomc_content }>
                    <div className={ investigacion.ciomc_des }>
                        <div className={ investigacion.ciomc_des__t1 }>Sea of Cortez Oceanic Research Center –SCORC-</div>
                        <div className={ investigacion.ciomc_des__title }>Lines of research</div>
                        <div className={ investigacion.ciomc_des__t2 }>The ORC is a research center dedicated to identifying solutions that benefit the oceans and the Sea of Cortez, by the hand of the new Gran Acuario de Mazatlán, by focusing on high-level research that connects our society with its natural environment.</div>
                        <div className={ investigacion.ciomc_des__sep }></div>
                        <div className={ investigacion.ciomc_des__title }>Laboratory</div>
                        <div className={ investigacion.ciomc_des__t2 }>Learn more about the habitats during your visit to the Oceanic Research Center, and about feeding practices and the special support systems that maintain optimum living conditions for all marine species.</div>
                    </div>
                    <div className={ investigacion.ciomc_links }>
                        <div className={ investigacion.ciomc_links__el }>
                            <Link className={ investigacion.ciomc_links__el_link } to="#" ></Link>
                            <div className={ investigacion.ciomc_links__el_title }>Mazatlán Scholarships<img src="/images/investigacion/chevron.png" alt="Chevron"/></div>
                            <div className={ investigacion.ciomc_links__el_prox }>(Coming soon)</div>
                        </div>
                        <div className={ investigacion.ciomc_links__el }>
                            <Link className={ investigacion.ciomc_links__el_link } to="#" ></Link>
                            <div className={ investigacion.ciomc_links__el_title }>Fund for the Conservation of the Sea of Cortez<img src="/images/investigacion/chevron.png" alt="Chevron"/></div>
                            <div className={ investigacion.ciomc_links__el_prox }>(Coming soon)</div>
                        </div>
                    </div>
                </div>
                <Regresar link="/eng/" des="Home" />
            </div>

            <FooterEng/>
        </>
    )
}