import { NavbarEng, SidebarEng } from "../components/Navigation"
import { useRef } from "react"
import { ResponsiveImage } from "../components/ResponsiveImage"
import Header from "../components/Header"
import contacto from "./../styles/contacto.module.css"
import cx from "classnames"
import FooterEng from "../components/FooterEng"

export default function Contacto() {
    const name = useRef(null);
    const email = useRef(null);
    const asunto = useRef(null);
    const msg = useRef(null);


    return (
        <>
            <NavbarEng/>
            <SidebarEng espLink={ "/contacto" }/>
            <Header routeName="contacto" altName="Render acuario" headerTitle="CONTACT US" titleClasses="default"/>

            {/* Contenido */}
            <div className={ cx(contacto.contenido, 'section') }>
                <div className={ cx(contacto.contenido_content, 'content')}>
                    <div className={ contacto.contenido_des }>
                        <div className={ contacto.contenido_des__title }>WE WANT TO HEAR FROM YOU</div>
                        <div className={ contacto.contenido_des__des }>Welcome to our contact section! We are delighted that you are interested in visiting us and want to assure you that you will be very well received. We look forward to meeting you and showing you the amazing Sea of Cortez.</div>
                        <div className={ contacto.contenido_des__des }>Our address is <a href="https://goo.gl/maps/ULHfEH2LtHDNXQcTA" target="_blank">Av. de los Deportes 111, Tellería, 82017 Mazatlán, Sinaloa.</a></div>
                        <div className={ contacto.contenido_des__des }>We are located in the heart of Mazatlan Central Park and are open Monday through Sunday all year round.</div>
                        <div className={ contacto.contenido_des__des }>In case you need to contact us, please do not hesitate to do so through our email <a href="mailto:contacto@granacuario.com">contacto@granacuario.com</a> or call us at <a href="tel:6696890501">(669) 6890501</a>. If you prefer to visit us in person, our office hours are from 10:00 am to 6:00 pm.</div>
                        <div className={ contacto.contenido_des__des }>We assure you that we will do our best to make your experience with us the best possible, we hope to see you soon!</div>
                    </div>
                    <div className={ contacto.contacto_links }>
                        <div className={ contacto.contacto_links_title }>DIRECT CONTACT</div>
                        <a href="mailto:contacto@granacuario.com" className={ contacto.contacto_links_el }>Send us an email at contacto@granacuario.com</a>
                        <a href="https://goo.gl/maps/ULHfEH2LtHDNXQcTA" target="_blank" className={ contacto.contacto_links_el }>Visit us at Av. de los Deportes 111, Tellería</a>
                        <a href="https://wa.me/+5216691390134?text=%C2%A1Hola%21+Tengo+una+duda+sobre+el+Gran+Acuario+Mazatl%C3%A1n" target="_blank" className={ contacto.contacto_links_el }>Send us a whatsapp message</a>
                        <div className={ contacto.contacto_links_title }>SOCIAL MEDIA</div>
                        <div className={ contacto.contacto_links_social }>
                            <a href="https://www.facebook.com/granacuariomazatlan" target="_blank" className={ contacto.contacto_links_social_el }><img src="/images/footer/fb_grey.svg" alt="Facebook Icon"/></a>
                            <a href="https://www.instagram.com/granacuariomazatlan/" target="_blank" className={ contacto.contacto_links_social_el }><img src="/images/footer/ins_grey.svg" alt="Instagram Icon"/></a>
                            <a href="https://twitter.com/GranAcuarioMzt" target="_blank" className={ contacto.contacto_links_social_el }><img src="/images/footer/tw_grey.svg" alt="Twitter Icon"/></a>
                        </div>
                    </div>
                    {/*
                    <div className={ contacto.contenido_form }>
                        <form>
                            <div className={ contacto.contenido_des__title }>Contacto</div>
                            <input className={ contacto.form_input } ref={ name } type="text" name="name" id="name" placeholder="Nombre" required/>
                            <input className={ contacto.form_input } ref={ email } type="email" name="email" id="email" placeholder="Correo" required/>
                            <input className={ contacto.form_input } ref={ asunto } type="text" name="asunto" id="asunto" placeholder="Asunto" required/>
                            <textarea className={ contacto.form_input } ref={ msg } name="mensaje" id="mensaje" placeholder="Mensaje" rows="5" required/>
                            <input className={ cx(contacto.form_input, contacto.form_send ) } type="submit" value="Enviar"/>
                        </form>
                    </div>
                    */}
                </div>
            </div>

            {/* Mapa */}
            <div className={ cx(contacto.mapa, 'section') }>
                <ResponsiveImage route="contacto/mapa" alt="Mapa acuario" />
                <div className={ contacto.mapa_des }>Open all year-around, Monday through Sunday.</div>
            </div>

            <FooterEng/>
        </>
    )
}