import { useEffect } from "react";
import { Link } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link";
import { NavbarButton, SidebarButton, SidebarSobrante } from "./NavigationButtons"
import "./../styles/global.css"

export function Navbar() {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.navbar').classList.add('fixed') : document.querySelector('.navbar').classList.remove('fixed');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    return (
        <div className="navbar section">
            <div className="content">
            <Link to="/" className="navbar_logo"><img src="/images/header/logo.svg" alt="Logo Gran Acuario Mazatlán"/></Link>
                <div className="navbar_options">
                    <Link to="/habitats" className="navbar_options__el">Hábitats</Link>
                    <Link to="/entradas" className="navbar_options__el">Entradas</Link>
                    <Link to="/educacion" className="navbar_options__el">Educación</Link>
                    <a href="https://tickets.granacuario.com/" target="_blank" className="navbar_options__tickets">{/*<img src="/images/header/entradas.svg" alt="Tickets Icon"/>*/}TICKETS</a>
                    <NavbarButton/>
                </div>
            </div>
            <div className="content_mobile">
                <Link to="#" className="content_mobile__el">TIENDA <span>(próximamente)</span></Link>
                <a href="https://tickets.granacuario.com/" target="_blank" className="content_mobile__el">TICKETS</a>
            </div>
        </div>
    )
}

export function NavbarEng() {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.navbar').classList.add('fixed') : document.querySelector('.navbar').classList.remove('fixed');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    return (
        <div className="navbar section">
            <div className="content">
            <Link to="/eng/" className="navbar_logo"><img src="/images/header/logo.svg" alt="Logo Gran Acuario Mazatlán"/></Link>
                <div className="navbar_options">
                    <Link to="/eng/habitats" className="navbar_options__el">Habitats</Link>
                    <Link to="/eng/ticketing" className="navbar_options__el">Ticketing</Link>
                    <Link to="/eng/education" className="navbar_options__el">Education</Link>
                    <a href="https://tickets.granacuario.com/" target="_blank" className="navbar_options__tickets">{/*<img src="/images/header/entradas.svg" alt="Tickets Icon"/>*/}TICKETS</a>
                    <NavbarButton/>
                </div>
            </div>
            <div className="content_mobile">
                <Link to="#" className="content_mobile__el">STORE <span>(soon)</span></Link>
                <a href="https://tickets.granacuario.com/" target="_blank" className="content_mobile__el">TICKETS</a>
            </div>
        </div>
    )
}

export function Sidebar({ engLink }) {
    return (
        <div className="sidebar">
            <SidebarSobrante/>
            <div className="sidebar_container">
                <SidebarButton/>
                <div className="sidebar_buttons">
                    <Link to="#" className="sidebar_tienda">TIENDA <span>(próximamente)</span></Link>
                    <a href="https://tickets.granacuario.com/" target="_blank" className="sidebar_entradas">TICKETS</a>
                </div>
                <div className="sidebar_language">
                    <Link to="" className="sidebar_language__el active">ESPAÑOL</Link>
                    <Link to={ engLink } className="sidebar_language__el">ENGLISH</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/entradas" className="sidebar_con__t1">ENTRADAS</Link></div>
                <div className="sidebar_con">
                    <Link to="/habitats" className="sidebar_con__t1">HÁBITATS</Link>
                    <Link to="/habitats/tierra" className="sidebar_con__t2">Tierra</Link>
                    <Link to="/habitats/costa" className="sidebar_con__t2">Costa</Link>
                    <Link to="/habitats/mar" className="sidebar_con__t2">Mar</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/educacion" className="sidebar_con__t1">EDUCACIÓN</Link></div>
                <div className="sidebar_con solo"><Link to="/investigacion" className="sidebar_con__t1">INVESTIGACIÓN</Link></div>
                <div className="sidebar_con solo"><Link to="/actividades" className="sidebar_con__t1">ACTIVIDADES</Link></div>
                <div className="sidebar_con solo"><Link to="/grupos" className="sidebar_con__t1">GRUPOS</Link></div>
                {/*<div className="sidebar_con solo"><Link to="#" target="_blank" className="sidebar_con__t1">TIENDA EN LÍNEA</Link></div>*/}
                <div className="sidebar_con">
                    <Link to="/visita" className="sidebar_con__t1">PLANIFICA TU VISITA</Link>
                    <NavHashLink to="/visita#tarifas" className="sidebar_con__t2">Tarifas y horarios</NavHashLink>
                    <NavHashLink to="/visita#recomendaciones" className="sidebar_con__t2">Recomendaciones</NavHashLink>
                    <NavHashLink to="/visita#plano" className="sidebar_con__t2">Plano del acuario</NavHashLink>
                </div>
                <div className="sidebar_con solo"><Link to="/faq" className="sidebar_con__t1">Preguntas frecuentes</Link></div>
                <div className="sidebar_con solo"><a href="tel:6696890501" className="sidebar_con__t1">Objetos perdidos</a></div>
                <div className="sidebar_con solo"><Link to="/contacto" className="sidebar_con__t1">Contacto</Link></div>
            </div>
        </div>
    )
}

export function SidebarEng({ espLink }) {
    return (
        <div className="sidebar">
            <SidebarSobrante/>
            <div className="sidebar_container">
                <SidebarButton/>
                <div className="sidebar_buttons">
                    <Link to="#" className="sidebar_tienda">STORE <span>(soon)</span></Link>
                    <a href="https://tickets.granacuario.com/" target="_blank" className="sidebar_entradas">TICKETS</a>
                </div>
                <div className="sidebar_language">
                    <Link to={ espLink } className="sidebar_language__el">ESPAÑOL</Link>
                    <Link to="#" className="sidebar_language__el active">ENGLISH</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/eng/ticketing" className="sidebar_con__t1">TICKETING</Link></div>
                <div className="sidebar_con">
                    <Link to="/eng/habitats" className="sidebar_con__t1">HABITATS</Link>
                    <Link to="/eng/habitats/land" className="sidebar_con__t2">Land</Link>
                    <Link to="/eng/habitats/coast" className="sidebar_con__t2">Coast</Link>
                    <Link to="/eng/habitats/sea" className="sidebar_con__t2">Sea</Link>
                </div>
                <div className="sidebar_con solo"><Link to="/eng/education" className="sidebar_con__t1">EDUCATION</Link></div>
                <div className="sidebar_con solo"><Link to="/eng/research" className="sidebar_con__t1">RESEARCH</Link></div>
                <div className="sidebar_con solo"><Link to="/eng/activities" className="sidebar_con__t1">ACTIVITIES</Link></div>
                <div className="sidebar_con solo"><Link to="/eng/groups" className="sidebar_con__t1">GROUPS</Link></div>
                {/*<div className="sidebar_con solo"><Link to="#" target="_blank" className="sidebar_con__t1">TIENDA EN LÍNEA</Link></div>*/}
                <div className="sidebar_con">
                    <Link to="/eng/visit" className="sidebar_con__t1">PLAN YOUR VISIT</Link>
                    <NavHashLink to="/visit#tarifas" className="sidebar_con__t2">Rates and Operating Hours</NavHashLink>
                    <NavHashLink to="/visit#recomendaciones" className="sidebar_con__t2">Recommendations</NavHashLink>
                    <NavHashLink to="/visit#plano" className="sidebar_con__t2">Plano del acuario</NavHashLink>
                </div>
                <div className="sidebar_con solo"><Link to="/eng/faq" className="sidebar_con__t1">FAQs</Link></div>
                <div className="sidebar_con solo"><Link to="/eng/contact" className="sidebar_con__t1">CONTACT US</Link></div>
            </div>
        </div>
    )
}

export function ExhibitionLink({ linkDes, path, classes="" }) {
    return ( <Link to={ path } class={ classes }>{ linkDes }</Link> )
}